import React, {Component} from 'react';
import goog from './images/google.png';
import bing from './images/bing.png';
import yahoo from './images/yahoo.png';
import yt from './images/yt.png';

class ClientTerms extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render(){
        return (
          <>
          <h3>Search Results for {this.props.customer.company}</h3>
          <ClientRender customer={this.props.customer} />
          </>
        );
      }
  }

class ClientRender extends Component {
  constructor(props, context) {
    super(props, context);

    this.customerData = this.customerData.bind(this);

    this.state = {
      terms: [],
      videos:[]
    };
  }

  // <div className="col-lg-4">
  //   <img src={this.state.testimonials[i].image} alt={this.state.testimonials[i].name} width="150" height="150" />
  // </div>

  componentWillMount(){
    this.customerData();
  }

  customerData() {
    let j=0;
    let k=0;
    fetch('/customerTerms')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (j in body){
            if (body[j].fullname === this.props.customer.fullname && body[j].username === this.props.customer.username){
              this.setState({terms: body[j]});
            }
          }
        // throw new Error(body.err)
      })
    )
    fetch('/adminTerms')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (j in body){
            if (body[j].fullname === this.props.customer.fullname && body[j].username === this.props.customer.username){
              this.setState({terms: body[j]});
            }
          }
        // throw new Error(body.err)
      })
    )
    fetch('/customerVideos')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (k in body){
            if (body[k].fullname === this.props.customer.fullname && body[k].username === this.props.customer.username){
              this.setState({videos: body[k]});
            }
          }
        // throw new Error(body.err)
      })
    )

  }

  render(){
    let rows = [];
    let i = 0;
    for ( i in this.state.terms ){
      let currentKey = "this.state.terms." + i.toString();
      if (currentKey !== 'this.state.terms.key0' && this.state.terms[i] !== null && currentKey !== 'this.state.terms.username' && currentKey !== 'this.state.terms.fullname' && currentKey !== 'this.state.terms.package'){
        rows.push(
          <>
            <div className="container" id="custkeybox">
              <div className="custkeytermbox col-12">
                <div className="row" id="rowheader">
                  <h4>{this.state.terms[i]}</h4>
                </div>
                <div className="row" id="rowlinks">
                  <div className="col-4">
                    <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.state.terms[i] + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="50" /></a>
                  </div>
                  <div className="col-4">
                    <a href={"https://www.bing.com/search?q=" + this.state.terms[i] + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="50" /></a>
                  </div>
                  <div className="col-4">
                    <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.state.terms[i] + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="50" /></a>
                  </div>
                  {this.state.terms[i] === this.state.videos.vidTitle1 &&
                    <div className="row vidlink">
                      <div className="col-12">
                        <a href={this.state.videos.vidLink1} target="_blank" rel="noopener noreferrer"><img src={yt} alt="YouTube Logo" height="50" /></a>
                      </div>
                    </div>
                  }
                  {this.state.terms[i] === this.state.videos.vidTitle2 &&
                    <div className="row vidlink">
                      <div className="col-12">
                        <a href={this.state.videos.vidLink2} target="_blank" rel="noopener noreferrer"><img src={yt} alt="YouTube Logo" height="50" /></a>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        )
      }
    }
    return(<>{rows}</>);
  }
}
export default ClientTerms;
