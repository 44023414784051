import React from 'react';
import {Modal, Form, Button} from 'react-bootstrap';

export default class MReview extends React.Component {
  constructor(props, context) {
      super(props, context);

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
      this.handleMRChange = this.handleMRChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);


      this.state = {
        show: false,
        type: '',
        phone: this.props.customer.phone,
        email: this.props.customer.emailaddress,
        address: this.props.customer.address,
        site: this.props.customer.site,
        train: 'No',
        script: "No, they don't need them.",
        othertype: "N/A",
        clientother: "N/A",
        ratio: "N/A"
      }
  }

  handleShow(){
    this.setState({show: true});
  }

  handleClose(){
    this.setState({show: false});
  }

  handleSelect(e){
    this.setState({type: e.target.value});
  }

  handleMRChange(e){
    let name = e.target.name;
    this.setState({[name]: e.target.value});
  }

  handleSubmit(){
    console.log(this.state);
    if (this.state.type === 'Home Business'){
      const pkg = {
        emailaddress: this.props.customer.emailaddress,
        product: this.state.product,
        demos: this.state.demos,
        areas: this.state.areas,
        price: this.state.price,
        close: this.state.close,
        train: this.state.train,
        script: this.state.script,
        how: this.state.how,
        inquire: this.state.inquire,
        process: this.state.process,
        url: this.state.url,
        numbers: this.state.numbers,
        bio: this.state.bio,
        socials: this.state.socials,
        bustype: this.state.bustype,
        othertype: this.state.othertype,
        verb: this.state.verb,
        back: this.state.back,
        logo: this.state.logo,
        suggest: this.state.suggest,
        goal: this.state.goal,
        kt: this.state.kt
      }
      fetch('/businessMR', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(
        this.setState({show:false})
      )
    } else if (this.state.type === 'Real Estate'){
      const pkg = {
        emailaddress: this.props.customer.emailaddress,
        bustype: this.state.bustype,
        othertype: this.state.othertype,
        trans: this.state.trans,
        certs: this.state.certs,
        years: this.state.years,
        desig: this.state.desig,
        broker: this.state.broker,
        buysell: this.state.buysell,
        ratio: this.state.ratio,
        areas: this.state.areas,
        cities: this.state.cities,
        neighborhood: this.state.neighborhood,
        specifics: this.state.specifics,
        range: this.state.range,
        client: this.state.client,
        clientother: this.state.clientother,
        socials: this.state.socials,
        updates: this.state.updates,
        phone: this.state.phone,
        site: this.state.site,
        email: this.state.email,
        address: this.state.address,
        bio: this.state.bio,
        awards: this.state.awards,
        kt: this.state.kt
      }
      fetch('/realMR', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(
        this.setState({show: false})
      )
    }
  }

  render(){
    return(
      <>
      <Button variant="dark" onClick={this.handleShow}>Marketing Review</Button>
      <Modal size="xl" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Marketing Review for {this.props.customer.fullname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" id="mrbox">
            <div className="row">
              <div className="col-12">
                <Form>
                  <Form.Group controlId="marketingReview">
                    <Form.Label>Home Business or Real Estate?</Form.Label>
                    <Form.Control as="select" onChange={this.handleSelect}>
                      <option>Pick One</option>
                      <option>Home Business</option>
                      <option>Real Estate</option>
                    </Form.Control>
                    {this.state.type === 'Home Business' &&
                      <>
                      <Form.Label>What are you selling (specific products)?</Form.Label>
                      <Form.Control type="text" name="product" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Who are you selling to?</Form.Label>
                      <Form.Control type="text" name="demos" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What are some good cities to target for these products?</Form.Label>
                      <Form.Control type="text" name="areas" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>How much does your product cost?</Form.Label>
                      <Form.Control type="text" name="price" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Do you close your deals?</Form.Label>
                      <Form.Control as="select" name="close" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Self-Close</option>
                        <option>Done For You</option>
                      </Form.Control>
                      <hr />
                      {this.state.close === 'Self-Close' &&
                        <>
                        <Form.Label>Do you need sales training?</Form.Label>
                        <Form.Control as="select" name="train" onChange={this.handleMRChange} value={this.state.train}>
                          <option>Pick One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </Form.Control>
                        <hr />
                        <Form.Label>Do you have scripts and rebuttals?</Form.Label>
                        <Form.Control as="select" name="script" onChange={this.handleMRChange} value={this.state.script}>
                          <option>Pick One</option>
                          <option>No, they don't need them.</option>
                          <option>No, but they'd like some.</option>
                          <option>Yes, but they don't match my site.</option>
                          <option>Yes, and they do match my site.</option>
                        </Form.Control>
                        <hr />
                        </>
                      }
                      <Form.Label>How do you sell the product and/or accept money?</Form.Label>
                      <Form.Control type="text" name="how" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>How do you get inquiries from each of your websites?</Form.Label>
                      <Form.Control type="text" name="inquire" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Describe your sales process.</Form.Label>
                      <Form.Control type="text" name="process" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What are your website URLs?</Form.Label>
                      <Form.Control type="text" name="url" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What are your contact numbers?</Form.Label>
                      <Form.Control type="text" name="numbers" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Do you have a bio for you or your business, and where can we find it?</Form.Label>
                      <Form.Control type="text" name="bio" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Do you have social media you'd like to have built into your marketing engine?</Form.Label>
                      <Form.Control as="select" name="socials" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Yes (have them email links to admin@the1stpagegroup.com)</option>
                        <option>No, but I'd like to.</option>
                        <option>No, I don't want social media linked to this.</option>
                      </Form.Control>
                      <hr />
                      <Form.Label>Are you operating as a sole-proprietor or as an LLC/Corporation/Other Business Entity?</Form.Label>
                      <Form.Control as="select" name="bustype" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Sole Prop</option>
                        <option>LLC</option>
                        <option>Corp</option>
                        <option>Other</option>
                      </Form.Control>
                      <hr />
                      {this.state.bustype === 'Other' &&
                        <>
                        <Form.Label>What type of business is it?</Form.Label>
                        <Form.Control type="text" name="othertype" onChange={this.handleMRChange} value={this.state.othertype} />
                        <hr />
                        </>
                      }
                      <Form.Label>What sales verbiage do you have?</Form.Label>
                      <Form.Control type="text" name="verb" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Does your program have a back office?</Form.Label>
                      <Form.Control as="select" name="back" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Control>
                      <hr />
                      <Form.Label>Do you have a logo for you business?</Form.Label>
                      <Form.Control as="select" name="logo" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Yes (send to admin@the1stpagegroup.com)</option>
                        <option>No, but I'd like one.</option>
                        <option>No, and I don't want one.</option>
                      </Form.Control>
                      <hr />
                      <Form.Label>We are going to create a new URL for your marketing engine that links to your site. Any suggestions for the URL?</Form.Label>
                      <Form.Control type="text" name="suggest" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What are you looking to accomplish (monthly/quarterly/yearly)?</Form.Label>
                      <Form.Control type="text" name="goal" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>When do you want to schedule your keyterm selection?</Form.Label>
                      <Form.Control type="text" name="kt" onChange={this.handleMRChange} />
                      </>
                    }
                    {this.state.type === "Real Estate" &&
                      <>
                      <Form.Label>What type of agent/business are you?</Form.Label>
                      <Form.Control as="select" name="bustype" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Real Estate Agent</option>
                        <option>Broker</option>
                        <option>Sole Prop</option>
                        <option>LLC</option>
                        <option>Corp</option>
                        <option>Other</option>
                      </Form.Control>
                      <hr />
                      {this.state.bustype === "Other" &&
                        <>
                        <Form.Label>If other, please explain:</Form.Label>
                        <Form.Control type="text" name="othertype" onChange={this.handleMRChange} />
                        <hr />
                        </>
                      }
                      <Form.Label>What type of real estate transactions are you looking for?</Form.Label>
                      <Form.Control type="text" name="trans" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What licenses/certifications do you have?</Form.Label>
                      <Form.Control type="text" name="certs" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>How many years have you been licensed?</Form.Label>
                      <Form.Control type="text" name="years" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What are your designations?</Form.Label>
                      <Form.Control type="text" name="desig" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Who is your broker?</Form.Label>
                      <Form.Control type="text" name="broker" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Do you work with buyers, sellers, or both?</Form.Label>
                      <Form.Control as="select" name="buysell" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Buyers</option>
                        <option>Sellers</option>
                        <option>Both</option>
                      </Form.Control>
                      <hr />
                      {this.state.buysell === 'Both' &&
                        <>
                        <Form.Label>If Both, what ratio?</Form.Label>
                        <Form.Control type="text" name="ratio" onChange={this.handleMRChange} placeholder="Buyers / Sellers" />
                        <hr />
                        </>
                      }
                      <Form.Label>What counties/areas do you work?</Form.Label>
                      <Form.Control type="text" name="areas" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What cities do you work in?</Form.Label>
                      <Form.Control type="text" name="cities" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What neighborhoods do you work in?</Form.Label>
                      <Form.Control type="text" name="neighborhood" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Do you have any specific master planned communities, school zones, zip codes, retirement communities, new home developments you want to target?</Form.Label>
                      <Form.Control type="text" name="specifics" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What is your ideal price range?</Form.Label>
                      <Form.Control type="text" name="range" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>What is your preferred client type?</Form.Label>
                      <Form.Control as="select" name="client" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>First Time Buyers</option>
                        <option>Investors</option>
                        <option>Luxury</option>
                        <option>Other</option>
                      </Form.Control>
                      <hr />
                      {this.state.client === 'Other' &&
                        <>
                        <Form.Label>Explain:</Form.Label>
                        <Form.Control type="text" name="clientother" onChange={this.handleMRChange} />
                        <hr />
                        </>
                      }
                      <Form.Label>Do you have any social media links you want on the site?</Form.Label>
                      <Form.Control as="select" name="socials" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Yes (send them to admin@the1stpagegroup.com)</option>
                        <option>No, but I'd like to.</option>
                        <option>No, and I don't want to.</option>
                      </Form.Control>
                      <hr />
                      <Form.Label>Is any of your contact information that will be displayed on your site different than what was on your order form?</Form.Label>
                      <Form.Control as="select" name="update" onChange={this.handleMRChange}>
                        <option>Pick One</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Control>
                      <hr />
                      {this.state.update === 'Yes' &&
                        <>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" name="phone" onChange={this.handleMRChange} value={this.state.phone} />
                        <hr />
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" name="site" onChange={this.handleMRChange} value={this.state.site} />
                        <hr />
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="email" onChange={this.handleMRChange} value={this.state.email} />
                        <hr />
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" name="address" onChange={this.handleMRChange} value={this.state.address} />
                        <hr />
                        </>
                      }
                      <Form.Label>Do you have a bio we can use on your site?</Form.Label>
                      <Form.Control type="text" name="bio" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>Any qualifications or awards you'd like to share on your site?</Form.Label>
                      <Form.Control type="text" name="awards" onChange={this.handleMRChange} />
                      <hr />
                      <Form.Label>When can we schedule your keyterm selection call?</Form.Label>
                      <Form.Control type="text" name="kt" onChange={this.handleMRChange} />
                      </>
                    }
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
          <Button variant="success" onClick={this.handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
      </>
    )
  }

}
